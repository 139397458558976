@import '../../../styles/variables.scss';
@import '../../../styles/mixins.scss';

.filters_help_tooltip {
  background-color: $blue-darker;
  border-radius: 8px;
  z-index: 999;
  position: absolute;
  width: 25rem;
  padding: 1rem;
  margin: auto 0 auto auto;
  color: $white;
  top: 2.3rem;
  right: -2.25rem;
  font-weight: 350;
  font-size: 14px;
  line-height: 20px;
}

.filters_help_tooltip::after {
  content: '';
  position: absolute;
  top: 0;
  right: 7.5%;
  border-width: 10px;
  border-style: solid;
  border-color: $blue-darker transparent transparent transparent;
  transform: rotate(180deg) translateY(100%);
}

.bell {
  cursor: pointer;
  display: flex;
  position: relative;
  .red_circle {
    height: 10px;
    background-color: #cc092f;
    color: white;
    border-radius: 50%;
    padding: 2px 5px;
    position: absolute;
    left: 10px;
    font-size: 9px;
  }
}
