///////////////////////////////////////////////////////////
// InputTextArea                             (Component) //
///////////////////////////////////////////////////////////
@import '../../../styles/mixins.scss';

.fieldset {
  width: 100%;
  &.full_width {
    grid-column: span 2;
    // Mobile
    @media (max-width: breakpoints(large)) {
      grid-column: 1;
    }
  }

  .labels_and_moderation {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    .label {
      font-size: 16px;
      color: $blue-secondary;

      .hasNote {
        padding-left: 5px;
        color: $gray;
        font-size: 14px;
      }
      .hasRequired {
        color: $red;
      }
    }
  }

  .disabled_text {
    color: $gray;
    background-color: $gray-lightest;
  }
}
