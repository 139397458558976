///////////////////////////////////////////////////////////
// Login Button                              (Component) //
///////////////////////////////////////////////////////////
@import '../../../styles/variables.scss';
@import '../../../styles/mixins';

.login_button {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  align-items: center;

  .row {
    display: flex;
    align-items: center;
    gap: 1rem;
  }

  @media (min-width: breakpoints(medium)) {
    gap: 1rem;
    align-items: flex-end;
    p {
      text-align: right;
      font-size: 16px;
    }
  }
}
