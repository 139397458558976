///////////////////////////////////////////////////////////
// InputText                                 (Component) //
///////////////////////////////////////////////////////////
@import '../../../styles/mixins.scss';

.full_width {
  grid-column: span 2;
  // Mobile
  @media (max-width: breakpoints(large)) {
    grid-column: 1;
  }
}

.container {
  position: relative;
  padding: 0;
  margin: 0;

  .image {
    position: absolute;
    top: 24px;
    right: 1rem;
  }

  .log {
    position: absolute;
    top: 8px;
    right: 8px;
    height: 130px;
    overflow-y: auto;
    width: 100%;
    margin-top: 30px;
    min-width: 70%;
    max-width: 18rem;
    background-color: #d4e1e8;
    z-index: 999;
    border-radius: 4px;
  }

  textarea {
    margin: 0.5rem 0 0 0;
    height: 100%;
    min-height: 87px;
  }
}

.requireReview {
  background-color: $input-was-changed-bg;
  border: 2px solid $input-was-changed-border;
}
.approved {
  border: 2px solid $input-approved-border;
  border-radius: 4px;
  &:focus {
    border: 2px solid $input-approved-border;
    box-shadow: unset;
  }
}
.dismiss {
  border: 2px solid $input-dismissed-border;
  border-radius: 4px;
  &:focus {
    border: 2px solid $input-dismissed-border;
    box-shadow: unset;
  }
}
.changed {
  border: 2px solid $input-changed-border;
  border-radius: 4px;
  &:focus {
    border: 2px solid $input-changed-border;
    box-shadow: unset;
  }
}
