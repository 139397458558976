///////////////////////////////////////////////////////////
// InputText                                 (Component) //
///////////////////////////////////////////////////////////
@import '../../../styles/mixins.scss';
@import '../../../styles/variables.scss';

.fieldset {
  width: 100%;

  input {
    margin-top: 5px;
    &.not_filled {
      color: $sonic-silver;
    }
  }

  .red_req {
    color: red;
  }
  &.full_width {
    grid-column: span 2;
    // Mobile
    @media (max-width: breakpoints(large)) {
      grid-column: 1;
    }
  }
  &.highlight {
    input {
      background: $bg-attention;
      border-radius: 2px;
      border: unset;
    }
  }
  .requireReview {
    color: $white;
    background-color: $red;
  }
  svg {
    cursor: pointer;
    margin-left: 5px;
  }
  .tooltip {
    background-color: unset;
    padding: unset;
    cursor: unset;
    &:hover {
      background-color: unset;
    }
    &:before {
      font-size: 12px;
    }
    &:after {
      white-space: pre;
      font-size: 12px;
    }
  }
}

.changed {
  border: 2px solid $bg-attention;
  border-radius: 2px;
  background-color: $bg-attention;
  &:focus {
    border: 2px solid $bg-attention;
    box-shadow: unset;
  }
}
