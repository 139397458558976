@import '../../../../styles/mixins';

.content {
  padding: 2rem 2rem 1rem 2rem;
  position: relative;
  button {
    right: 50px;
    position: absolute;
  }

  .new_releases {
    padding-top: 1rem;
    overflow-y: auto;
    height: 250px;
    width: 103%;
    .releases {
      display: grid;

      .release {
        padding-bottom: 1rem;
        span {
          color: #6fcf97;
          border: 1px solid #6fcf97;
          padding: 10px;
          border-radius: 10px;
          font-size: 12px;
          svg {
            path {
              fill: #6fcf97;
            }
          }
          &.last_releases {
            color: #53565a;
            border: 1px solid #53565a;
            svg {
              path {
                fill: #53565a;
              }
            }
          }
        }
        ul {
          li {
            color: #53565a;
            font-size: 14px;
            font-weight: 325;
            padding: 3px;
          }
        }
        p {
          font-weight: 350;
          margin-top: 1.6rem;
          color: #1f384c;
        }
      }
      .last_updates {
        display: flex;
        gap: 10px;
        cursor: pointer;
        width: fit-content;
        height: 20px;
        margin-bottom: 1rem;
        svg {
          align-self: center;
        }
        p {
          padding-bottom: 1rem;
          color: #2360a0;
          font-weight: 350;
        }
      }
    }
  }
}
