@import '../../../../styles/mixins';

.content {
  padding: 2rem 2rem 1rem 2rem;
  overflow-y: auto;
  height: 20rem;
  position: relative;
  button:first-of-type {
    float: right;
  }
  .buttons {
    right: 33px;
    position: absolute;
  }

  .categories {
    padding-top: 3rem;
    label {
      font-size: 16px;
      color: #1f384c;
    }
    .feature_input {
      display: flex;
      gap: 3rem;
      align-items: center;
      fieldset {
        div:last-of-type {
          margin-top: unset;
        }
      }
    }
  }
  .remove_button {
    display: flex;
    height: 100%;
    justify-content: center;
    align-items: center;
    background: #fafafb;
    border: 1px solid rgba(228, 224, 224, 0.13);
    box-shadow: 0px 3.47368px 13.8947px rgba(51, 51, 51, 0.08);
    border-radius: 4px;
    cursor: pointer;
  }
}
